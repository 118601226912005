"use client";

import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import localizedFormatPlugin from "dayjs/plugin/localizedFormat";
import relativeTimePlugin from "dayjs/plugin/relativeTime";
import { ApolloProvider } from "@apollo/client";
import { ThemeRegistry } from "global/providers/ThemeRegistry";
import CssBaseline from "@mui/material/CssBaseline";
import { apolloClient } from "config";
import React, { ReactNode } from "react";

dayjs.extend(localizedFormatPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(relativeTimePlugin);

export const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeRegistry options={{ key: "mui" }}>
        <CssBaseline />
        {children}
      </ThemeRegistry>
    </ApolloProvider>
  );
};
