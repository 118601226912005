"use client";

import { lightTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { wagmiConfig } from "config/wagmi";
import { ReactNode } from "react";
import { cookieToInitialState, WagmiProvider } from "wagmi";

export const queryClient = new QueryClient({});

export const Web3Provider = ({
  children,
  cookie,
}: Readonly<{ children: ReactNode; cookie: string }>) => {
  return (
    <WagmiProvider
      config={wagmiConfig}
      initialState={cookieToInitialState(wagmiConfig, cookie)}
    >
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          theme={lightTheme({
            accentColor: "#212122",
            accentColorForeground: "white",
            borderRadius: "medium",
            fontStack: "system",
          })}
        >
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
