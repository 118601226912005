import { TokenStandard, type WarpCoreConfig } from "@hyperlane-xyz/sdk";

const pics = {
  usdt: "https://raw.githubusercontent.com/trustwallet/assets/d893e916c6947b6bdbf1661fc7fca13430584788/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
  usdc: "https://raw.githubusercontent.com/trustwallet/assets/77ef0d99eaf03aa766d64f7bd02eeb41c3b1604d/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
};

// A list of Warp Route token configs
// These configs will be merged with the warp routes in the configured registry
// The input here is typically the output of the Hyperlane CLI warp deploy command
export const warpRouteConfigs: WarpCoreConfig = {
  tokens: [
    {
      // USDС | Optimism => Prom
      chainName: "prom",
      standard: TokenStandard.EvmHypSynthetic,
      decimals: 6,
      symbol: "USDC",
      name: "USDC",
      // The router address
      addressOrDenom: "0xd9c95e2ad330E11D7Dfa58f18504049f625E955e",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0xd9c95e2ad330E11D7Dfa58f18504049f625E955e",
      logoURI: pics.usdc,
      connections: [
        {
          token: "ethereum|arbitrum|0x736f1150203E88E6aBA183f216A4a51b6ca12156",
        },
        {
          token: "ethereum|ethereum|0x424712Bec7c94a0F804c50F77B641A24F33A138e",
        },
        {
          token: "ethereum|optimism|0xEa1A15ed2710f860a38091877c6A84b18206b501",
        },
        {
          token: "ethereum|polygon|0x93b637AEA6a0dF51E8E24E49C62da34f616491c5",
        },
      ],
    },
    {
      // USDС | Polygon => Prom
      chainName: "polygon",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 6,
      symbol: "USDC",
      name: "USDC",
      // The router address
      addressOrDenom: "0x93b637AEA6a0dF51E8E24E49C62da34f616491c5",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
      logoURI: pics.usdc,
      connections: [
        { token: "ethereum|prom|0xd9c95e2ad330E11D7Dfa58f18504049f625E955e" },
      ],
    },
    {
      // USDС | Optimism => Prom
      chainName: "optimism",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 6,
      symbol: "USDC",
      name: "USDC",
      // The router address
      addressOrDenom: "0xEa1A15ed2710f860a38091877c6A84b18206b501",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
      logoURI: pics.usdc,
      connections: [
        { token: "ethereum|prom|0xd9c95e2ad330E11D7Dfa58f18504049f625E955e" },
      ],
    },
    {
      // USDС | Ethereum => Prom
      chainName: "ethereum",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 6,
      symbol: "USDC",
      name: "USDC",
      // The router address
      addressOrDenom: "0x424712Bec7c94a0F804c50F77B641A24F33A138e",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      logoURI: pics.usdc,
      connections: [
        { token: "ethereum|prom|0xd9c95e2ad330E11D7Dfa58f18504049f625E955e" },
      ],
    },
    {
      // USDС | Arbitrum => Prom
      chainName: "arbitrum",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 6,
      symbol: "USDT",
      name: "USDC",
      // The router address
      addressOrDenom: "0x736f1150203E88E6aBA183f216A4a51b6ca12156",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
      logoURI: pics.usdc,
      connections: [
        { token: "ethereum|prom|0xd9c95e2ad330E11D7Dfa58f18504049f625E955e" },
      ],
    },
    {
      // USDС | Arbitrum => Prom
      chainName: "arbitrum",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 6,
      symbol: "USDT",
      name: "USD Tether",
      // The router address
      addressOrDenom: "0x736f1150203E88E6aBA183f216A4a51b6ca12156",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
      logoURI: pics.usdt,
      connections: [
        { token: "ethereum|prom|0xd9c95e2ad330E11D7Dfa58f18504049f625E955e" },
      ],
    },
    {
      // USDС | BSC => Prom
      chainName: "bsc",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 18,
      symbol: "USDC",
      name: "USD Coin",
      // The router address
      addressOrDenom: "0xb4aF6757691573f0acAbA35A724a8E0EE049aB5a",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      logoURI: pics.usdt,
      connections: [
        { token: "ethereum|prom|0x451c22b06a5f38a43641f6910834C90f3619cEb9" },
      ],
    },
    {
      // USDС | PROM => BSC
      chainName: "prom",
      standard: TokenStandard.EvmHypSynthetic,
      decimals: 18,
      symbol: "USDC",
      name: "USD Coin",
      // The router address
      addressOrDenom: "0x451c22b06a5f38a43641f6910834C90f3619cEb9",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0x451c22b06a5f38a43641f6910834C90f3619cEb9",
      logoURI: pics.usdt,
      connections: [
        { token: "ethereum|bsc|0xb4aF6757691573f0acAbA35A724a8E0EE049aB5a" },
      ],
    },
    {
      // USDT | Arbitrum => Prom
      chainName: "polygon",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 6,
      symbol: "USDT",
      name: "USD Tether",
      // The router address
      addressOrDenom: "0x009eA8aBe4CE04fFA4A2b2Ec62cBAC859fc48B19",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      logoURI: pics.usdt,
      connections: [
        { token: "ethereum|prom|0x7e942605B5028E3B751dBB5Ef8afC5CF85a5A7eD" },
      ],
    },
    {
      // USDT | Arbitrum => Prom
      chainName: "arbitrum",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 6,
      symbol: "USDT",
      name: "USD Tether",
      // The router address
      addressOrDenom: "0xB9D49E18c69628C6D6B12Ab06F1918C4c4fe9546",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
      logoURI: pics.usdt,
      connections: [
        { token: "ethereum|prom|0x7e942605B5028E3B751dBB5Ef8afC5CF85a5A7eD" },
      ],
    },
    {
      // USDT | Prom => Any USDT collateral network but BSC
      chainName: "prom",
      standard: TokenStandard.EvmHypSynthetic,
      decimals: 6,
      symbol: "USDT",
      name: "USD Tether",
      // The router address
      addressOrDenom: "0x7e942605B5028E3B751dBB5Ef8afC5CF85a5A7eD",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0x7e942605B5028E3B751dBB5Ef8afC5CF85a5A7eD",
      logoURI: pics.usdt,
      connections: [
        {
          token: "ethereum|optimism|0xE9ef49e77c9f4751fBb0176836da70D062bBae02",
        },
        {
          token: "ethereum|ethereum|0xBA18df45758a09647F1902a06807844EA5DD47b4",
        },
        {
          token: "ethereum|polygon|0x009eA8aBe4CE04fFA4A2b2Ec62cBAC859fc48B19",
        },
        {
          token: "ethereum|arbitrum|0xB9D49E18c69628C6D6B12Ab06F1918C4c4fe9546",
        },
      ],
    },
    {
      // USDT | Prom => BSC
      chainName: "bsc",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 18,
      symbol: "USDT",
      name: "USD Tether",
      // The router address
      addressOrDenom: "0xCf2D543345728da295CC5d3A3d54B117979643f8",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0x55d398326f99059fF775485246999027B3197955",
      logoURI: pics.usdt,
      connections: [
        {
          token: "ethereum|prom|0x6064C9028d069a7822d30EF17065A57524A5FcAb",
        },
      ],
    },
    {
      // USDT | Prom => BSC
      chainName: "prom",
      standard: TokenStandard.EvmHypSynthetic,
      decimals: 18,
      symbol: "USDT",
      name: "USD Tether",
      // The router address
      addressOrDenom: "0x6064C9028d069a7822d30EF17065A57524A5FcAb",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0x6064C9028d069a7822d30EF17065A57524A5FcAb",
      logoURI: pics.usdt,
      connections: [
        {
          token: "ethereum|bsc|0xCf2D543345728da295CC5d3A3d54B117979643f8",
        },
      ],
    },
    {
      // USDT | Ethereum => Prom
      chainName: "ethereum",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 6,
      symbol: "USDT",
      name: "USD Tether",
      // The router address
      addressOrDenom: "0xBA18df45758a09647F1902a06807844EA5DD47b4",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      logoURI: pics.usdt,
      connections: [
        { token: "ethereum|prom|0x7e942605B5028E3B751dBB5Ef8afC5CF85a5A7eD" },
      ],
    },
    {
      // USDT | Optimism => Prom
      chainName: "optimism",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 6,
      symbol: "USDT",
      name: "USD Tether",
      // The router address
      addressOrDenom: "0xE9ef49e77c9f4751fBb0176836da70D062bBae02",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
      logoURI: pics.usdt,
      connections: [
        { token: "ethereum|prom|0x7e942605B5028E3B751dBB5Ef8afC5CF85a5A7eD" },
      ],
    },
    {
      // PROM Token | PROM Network - PROM<>BSC
      chainName: "prom",
      standard: TokenStandard.EvmHypNative,
      decimals: 18,
      symbol: "PROM",
      name: "PROM",
      // The router address
      addressOrDenom: "0xc1f4b896B94bd57dEfB7b6e630Bb91995b86CbAC",
      logoURI:
        "https://raw.githubusercontent.com/trustwallet/assets/d893e916c6947b6bdbf1661fc7fca13430584788/blockchains/ethereum/assets/0xfc82bb4ba86045Af6F327323a46E80412b91b27d/logo.png",
      connections: [
        { token: "ethereum|bsc|0x700b9322F6Cce0c8EFF4754bE9AcE88Ce6582821" },
      ],
    },
    {
      // PROM Token | BSC Network - PROM<>BSC ||||| BSC Network - PRO Network
      chainName: "bsc",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 18,
      symbol: "PROM",
      name: "PROM",
      // The router address
      addressOrDenom: "0x700b9322F6Cce0c8EFF4754bE9AcE88Ce6582821",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0xaf53d56ff99f1322515e54fdde93ff8b3b7dafd5",
      logoURI:
        "https://raw.githubusercontent.com/trustwallet/assets/d893e916c6947b6bdbf1661fc7fca13430584788/blockchains/ethereum/assets/0xfc82bb4ba86045Af6F327323a46E80412b91b27d/logo.png",
      connections: [
        { token: "ethereum|prom|0xc1f4b896B94bd57dEfB7b6e630Bb91995b86CbAC" },
      ],
    },
    {
      // PROM Token | ETH Network - ETH<>BSC
      chainName: "ethereum",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 18,
      symbol: "PROM",
      name: "PROM",
      // The router address
      addressOrDenom: "0x25C147Bd61bdaB6ce5A1Ae4939d2765092e0e2F8",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0xfc82bb4ba86045af6f327323a46e80412b91b27d",
      logoURI:
        "https://raw.githubusercontent.com/trustwallet/assets/d893e916c6947b6bdbf1661fc7fca13430584788/blockchains/ethereum/assets/0xfc82bb4ba86045Af6F327323a46E80412b91b27d/logo.png",
      connections: [
        { token: "ethereum|bsc|0xA83e1e21e82038e425e7a47aD7c2e059D7F1cae6" },
      ],
    },
    {
      // PROM Token | BSC Network - ETH<>BSC
      chainName: "bsc",
      standard: TokenStandard.EvmHypCollateral,
      decimals: 18,
      symbol: "PROM",
      name: "PROM",
      // The router address
      addressOrDenom: "0xA83e1e21e82038e425e7a47aD7c2e059D7F1cae6",
      // The address of the underlying collateral token
      collateralAddressOrDenom: "0xaf53d56ff99f1322515e54fdde93ff8b3b7dafd5",
      logoURI:
        "https://raw.githubusercontent.com/trustwallet/assets/d893e916c6947b6bdbf1661fc7fca13430584788/blockchains/ethereum/assets/0xfc82bb4ba86045Af6F327323a46E80412b91b27d/logo.png",
      connections: [
        {
          token: "ethereum|ethereum|0x25C147Bd61bdaB6ce5A1Ae4939d2765092e0e2F8",
        },
      ],
    },
  ],
  options: {},
};
