"use client";

import { alpha, createTheme } from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";
import { undefined } from "zod";

type ShadowsTail = Shadows extends [any, any, ...infer R] ? R : never;

const typographyTheme = createTheme({
  typography: {
    fontFamily: "'Basier Circle', sans-serif",
    button: {
      fontSize: "0.875rem",
      fontWeight: 600,
      textTransform: "initial",
    },
    h1: {
      fontSize: "3.25rem",
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.625rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    // h4: {
    //   fontWeight: 600,
    //   fontSize: "1.3rem",
    //   letterSpacing: "0.04em",
    // },
    subtitle1: {
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: 1.2, //correct?
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: "1.125rem",
      lineHeight: 1.2, //correct?
    },
    body1: {
      letterSpacing: "0.03em", //correct?
      fontSize: "1rem",
      lineHeight: 1.375,
    },
    body2: {
      fontSize: "0.875rem",
    },
    highlight: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
  },
});

export const paletteTheme = createTheme({
  ...typographyTheme,
  spacing: 4,
  palette: {
    mode: "light",
    background: {
      default: "#F9F9F9",
      dark: "#212122",
    },
    primary: {
      main: "#212122",
      light: "#0E0E0E",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#212121",
    },
    action: {
      disabled: "#ECECEC", // Disabled buttons
    },
    text: {
      primary: "#212122",
      secondary: "#85878A",
      disabled: "#888888",
    },
    error: {
      main: "#D83F48",
    },
    success: {
      main: "#00A154",
    },
    grey: {
      50: "#F9F9F9",
      100: "#F2F2F2",
      200: "#D0D0D0",
      300: "#ECECEC",
      A200: "rgba(208, 208, 208, 0.5)",
    },
    // TODO: fill if needed
    navigation: {},
    bar: {
      main: "#D9D9D9",
    },
    label: {
      dark: "#212122",
      light: "#F9F9F9",
      gray: "rgba(33, 33, 34, 0.7)",
    },
    highlight: {
      main: "#EF6A7D",
    },
  },
  shadows: [
    "none",
    "0px 2px 9px rgba(18, 18, 18, 0.15)",
    ...(typographyTheme.shadows.slice(2) as ShadowsTail),
  ],
});

export const lightTheme = createTheme({
  colorSchemes: undefined,
  ...paletteTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mediumLarge: 1096,
      lg: 1312,
      xl: 1792,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingInline: 20,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          top: "100%",
          "&.Mui-error": {
            fontWeight: 400,
            color: "#D83F48", // TODO: Replace
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 8,
          paddingBlock: 10,

          "&.Mui-disabled": {
            background: paletteTheme.palette.action.disabled,
            color: paletteTheme.palette.text.disabled,
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: paletteTheme.palette.primary.light,
          },
          "&.Mui-disabled": {
            background: paletteTheme.palette.action.disabled,
            color: paletteTheme.palette.text.disabled,
          },
        },
        // outlinedPrimary: {
        //   // color: paletteTheme.palette.text.secondary,
        //   // background: paletteTheme.palette.primary.main,
        //   border: `1px solid ${paletteTheme.palette.primary.main}`,

        //   "&:hover": {
        //     //TODO: Update
        //     color: paletteTheme.palette.text.secondary,
        //     background: paletteTheme.palette.primary.main,
        //     border: `1px solid ${paletteTheme.palette.primary.main}`,
        //   },
        // },
        outlinedSecondary: {
          color: paletteTheme.palette.primary.main,
          background: paletteTheme.palette.background.default,
          border: `1px solid currentColor`,
          "&:hover": {
            //TODO: Update
            color: paletteTheme.palette.primary.main,
            background: paletteTheme.palette.common.white,
            border: `1px solid currentColor`,
          },
          "&.Mui-disabled": {
            background: paletteTheme.palette.action.disabled,
            color: paletteTheme.palette.text.disabled,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: paletteTheme.typography.body1.fontSize,
          paddingBlock: paletteTheme.spacing(3),
          paddingRight: paletteTheme.spacing(3),
          lineHeight: paletteTheme.typography.body1.lineHeight,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },
        multiline: {
          paddingBlock: 2,
        },
        root: {
          outline: "none",
          borderRadius: 8,
          backgroundColor: paletteTheme.palette.background.default,
          borderWidth: 1,
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: paletteTheme.palette.grey[200],
            top: 0,
            "> legend": {
              display: "none",
            },
          },
          "&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
            borderColor: paletteTheme.palette.primary.main,
            borderWidth: 1,
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: paletteTheme.palette.error.main,
            borderWidth: 1,
          },
          "&:hover:not(.Mui-error)  .MuiOutlinedInput-notchedOutline": {
            borderColor: paletteTheme.palette.primary.main,
          },
        },
        notchedOutline: {
          borderColor: paletteTheme.palette.primary.main,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          position: "relative",
          transform: "none",
          fontSize: "0.75rem",
          marginLeft: 1,
          marginBottom: 2,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          background: paletteTheme.palette.background.default,
          ".MuiToolbar-root": {
            height: "80px",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 0,
          padding: 0,
          marginInlineEnd: paletteTheme.spacing(6),
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {},
        colorSecondary: {
          backgroundColor: "rgba(208, 208, 208, 0.8)",
          color: paletteTheme.palette.text.primary,
          ...typographyTheme.typography.body2,
        },
      },
      variants: [
        {
          props: {
            color: "default",
          },
          style: {
            height: 28,
            backgroundColor: paletteTheme.palette.background.default,
            color: paletteTheme.palette.text.primary,
            boxShadow: paletteTheme.shadows[1],
            paddingInline: 0,
            paddingBlock: paletteTheme.spacing(1),
            borderRadius: 20,
            ".MuiChip-label": {
              paddingInline: paletteTheme.spacing(3),
            },
            ".MuiChip-avatar": {
              marginLeft: paletteTheme.spacing(2),
              width: 20,
              height: 20,
            },
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        filledWarning: {
          backgroundColor: paletteTheme.palette.grey[300],
          color: paletteTheme.palette.text.primary,
          borderRadius: 8,
          alignItems: "center",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          boxShadow: "0px 2px 9px rgba(18, 18, 18, 0.15)",
          backdropFilter: "blur(20px)",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha(paletteTheme.palette.text.primary, 0.9),
        },
        arrow: {
          color: alpha(paletteTheme.palette.text.primary, 0.9),
        },
      },
    },
  },
});
