import {
  ApolloClient,
  ApolloLink,
  FieldPolicy,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import introspectionResult from "modules/shared/graphql/stitched";

const chainIdModifier: Record<string, FieldPolicy> = {
  chainId: {
    read(_, { field }) {
      const staticIntValue = field?.directives?.find(
        (directive) => directive.name.value === "staticIntValue"
      );
      if (staticIntValue) {
        const argument = staticIntValue.arguments![0]!.value;
        if (argument.kind === "IntValue") {
          return parseInt(argument.value, 10);
        }
      }
      return 42;
    },
  },
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    new HttpLink({
      uri:
        typeof window === "undefined" || process.env.NODE_ENV !== "production"
          ? "https://cms.prom.io/graphql" // TODO: Update URL to look just at cms.prom.io
          : "https://cms.prom.io/graphql",
      fetch: (...args) => fetch(...args),
    }),
  ]),
  cache: new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    addTypename: true,
    typePolicies: {
      _Meta_: {
        fields: {
          ...chainIdModifier,
        },
      },
      Vault: {
        fields: {
          ...chainIdModifier,
        },
      },
      TradeTransaction: {
        fields: {
          ...chainIdModifier,
        },
      },
      Query: {
        fields: {},
      },
    },
  }),
});
